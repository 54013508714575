import { useSelector } from '../../hooks';
import useAppStatus from '../../hooks/app-status';

export const Hint = () => {
  const appStatus = useAppStatus();
  const hint = useSelector((state) => state.hint);

  const text = appStatus === 'loading' ? 'Cargando...' : hint;

  return <p>{text || `\u00a0`}</p>;
};
