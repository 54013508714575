import moment from 'moment-timezone';

export const getTodayString = (): string => {
  return moment().tz('America/Santiago').format('YYYY-MM-DD');
};

export const getNowString = (): string =>
  moment().tz('America/Santiago').toISOString();

export const aYearAgoString = (): string =>
  moment().subtract(1, 'year').tz('America/Santiago').format('YYYY-MM-DD');

export const isYesterday = (date: string | null): boolean => {
  if (date === null) {
    return false;
  }

  return (
    date ===
    moment().tz('America/Santiago').subtract(1, 'day').format('YYYY-MM-DD')
  );
};
