import moment from 'moment-timezone';
import ReactCountdown from 'react-countdown';

interface RendererInput {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const renderer = ({ hours, minutes, seconds, completed }: RendererInput) => {
  const remainingHours = `${hours}`.padStart(2, '0');
  const remainingMinutes = `${minutes}`.padStart(2, '0');
  const remainingSeconds = `${seconds}`.padStart(2, '0');

  if (completed) {
    return <p>La siguiente palabra ya está disponible! Refresca la página</p>;
  } else {
    // Render a countdown
    return (
      <p>
        Siguiente palabra en {remainingHours}:{remainingMinutes}:
        {remainingSeconds}
      </p>
    );
  }
};

const Countdown = () => {
  return (
    <ReactCountdown
      date={moment()
        .tz('America/Santiago')
        .add(1, 'days')
        .startOf('day')
        .valueOf()}
      daysInHours={true}
      renderer={renderer}
    />
  );
};

export default Countdown;
