import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from '../../hooks';
import { looseAndSave } from '../../store';
import { GameState } from '../../types';
import styles from './give-up-button.module.css';

export const GiveUpButton = () => {
  const dispatch = useDispatch();
  const gameState = useSelector((state) => state.gameState);
  const guesses = useSelector((state) => state.guesses);
  const savingStatus = useSelector((state) => state.savingStatus);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(gameState === GameState.Playing && guesses.length > 0);
  }, [gameState, guesses]);

  return isEnabled ? (
    <button
      className={styles['give-up-button']}
      onClick={() => {
        setIsEnabled(false);

        if (savingStatus === 'ready') {
          dispatch(looseAndSave());
        }
      }}
    >
      Rendirse
    </button>
  ) : null;
};
