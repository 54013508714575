import netlifyIdentity from 'netlify-identity-widget';
import { useCallback, useEffect } from 'react';

const getIframeWindow = () => {
  const iframe = document.getElementById(
    'netlify-identity-widget',
  ) as HTMLIFrameElement;

  if (!iframe?.contentWindow) {
    console.error('Login iframe not found');

    return null;
  }

  return iframe.contentWindow;
};

const injectCssIntoLoginIframe = () => {
  const iframeWindow = getIframeWindow();

  if (iframeWindow) {
    const cssLink = document.createElement('link');
    cssLink.href = 'login-modal.css';
    cssLink.rel = 'stylesheet';
    cssLink.type = 'text/css';
    iframeWindow.document.head.appendChild(cssLink);
  }
};

const addHidingClasses = async (selectors: string[]) => {
  const iframeWindow = getIframeWindow();

  if (iframeWindow) {
    for (const selector of selectors) {
      const element = iframeWindow.document.body.querySelector(selector);

      if (element) {
        element.classList.add('force-hide');
      } else {
        console.log('element not mounted');
      }
    }
  }
};

export const useLoginModal = () => {
  if (process.env.REACT_APP_HIDE_LOGIN_FORM !== 'true') {
    return;
  }

  const observerHandler = useCallback(() => {
    const user = netlifyIdentity.currentUser();

    if (!user) {
      addHidingClasses(['.form']);
    }
  }, []);

  useEffect(() => {
    injectCssIntoLoginIframe();
  }, []);

  useEffect(() => {
    const iframeWindow = getIframeWindow();

    if (!iframeWindow) {
      return;
    }

    const observer = new MutationObserver(observerHandler);

    observer.observe(iframeWindow.document.body, {
      subtree: true,
      childList: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [observerHandler]);
};
