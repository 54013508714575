import { useCallback, useEffect, useState } from 'react';

import { type Theme } from '../types';

const getTheme = (): Theme => {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

export const useTheme = (): Theme => {
  const [theme, setTheme] = useState<Theme>(getTheme());

  const mqListener = useCallback(() => {
    setTheme(getTheme());
  }, []);

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addEventListener('change', mqListener);

    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, [mqListener]);

  return theme;
};
