import { useEffect, useState } from 'react';

import { useSelector } from '../../hooks';
import { type Clue, getClueClass } from '../../utils/clue';
import rowStyles from '../row/row.module.css';
import keyboardStyles from './keyboard.module.css';

interface KeyboardProps {
  keyboardClues: Record<string, Clue>;
  onKey: (key: string) => void;
}

type KeyProps = {
  label: string;
  onKey: (label: string) => void;
  clueVariant?: Clue;
};

const keyboard = [
  'q w e r t y u i o p'.split(' '),
  'a s d f g h j k l ñ'.split(' '),
  'Backspace z x c v b n m Enter'.split(' '),
];

export const Key = ({ label, onKey, clueVariant }: KeyProps) => {
  const [clueClass, setClueClass] = useState('');

  useEffect(() => {
    if (clueVariant === undefined) {
      return;
    }

    const styleTimeout = setTimeout(
      () => {
        setClueClass(rowStyles[getClueClass(clueVariant)]);
      },
      250 * 5 + 100,
    );

    return () => clearTimeout(styleTimeout);
  }, [clueVariant]);

  return (
    <div
      tabIndex={-1}
      className={`Game-keyboard-button ${
        label.length > 1 && 'Game-keyboard-button-wide'
      } ${clueClass}`}
      onClick={() => onKey(label)}
    >
      {label}
    </div>
  );
};

export function Keyboard(props: KeyboardProps) {
  const savingStatus = useSelector((state) => state.savingStatus);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(savingStatus === 'ready');
  }, [savingStatus]);

  return (
    <div className={`Game-keyboard ${isEnabled ? '' : keyboardStyles['blur']}`}>
      {keyboard.map((row, i) => (
        <div key={i} className="Game-keyboard-row">
          {row.map((label) => (
            <Key
              key={label}
              label={label === 'Backspace' ? '⌫' : label}
              onKey={() => {
                if (isEnabled) {
                  props.onKey(label);
                }
              }}
              clueVariant={props.keyboardClues[label]}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
