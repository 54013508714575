import type { ButtonHTMLAttributes } from 'react';

import { useTheme } from '../../hooks/use-theme';
import { type Theme } from '../../types';
import styles from './pill-button.module.css';

type PillButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: Theme;
};

export const PillButton = (props: PillButtonProps) => {
  const { children, onClick, className } = props;
  const theme = useTheme();
  const isDark = props.theme ? props.theme === 'dark' : theme === 'dark';

  return (
    <button
      className={`${styles['pill-button']} ${isDark ? styles.dark : ''} ${
        className ? className : ''
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
