import { type ComponentProps, useEffect, useState } from 'react';

import Share from '../../../components/share/share';
import Definitions from '../../../Definitions/Definitions';
import { useDispatch, useSelector } from '../../../hooks';
import useTargetWord from '../../../hooks/target-word-hook';
import { useAnimatedNumber } from '../../../hooks/use-animated-number';
import logo from '../../../images/Emoji_u1f40d.svg';
import { loadUserStats } from '../../../store';
import { GameState } from '../../../types';
import { Modal } from '../../modal/modal';
import styles from './stats-modal.module.css';

type NumberLabelProps = {
  value?: number;
  label: string;
};

type DistBarProps = {
  label: string;
  value: number;
  total: number;
};

const NumberLabel = ({ value = 0, label }: NumberLabelProps) => {
  const animatedNumber = useAnimatedNumber({ from: 0, to: value });

  return (
    <div className={styles['number-label']}>
      <span className={styles['number-label-qty']}>{animatedNumber}</span>
      <span className={styles['number-label-text']}>{label}</span>
    </div>
  );
};

const DistribBar = ({ label, value, total }: DistBarProps) => {
  const fillPercentage = total ? Math.round((value * 100) / total) : 0;
  const animatedNumber = useAnimatedNumber({ from: 0, to: value });

  return (
    <div className={styles['dist-bar-container']}>
      <span style={{ width: '5%' }}>{label}</span>
      <div style={{ width: '95%' }}>
        <div
          className={styles['dist-bar-fill']}
          style={{ width: `${fillPercentage}%` }}
        >
          {animatedNumber}
        </div>
      </div>
    </div>
  );
};

const DailyResults = () => {
  const { targetWord } = useTargetWord();
  const gameState = useSelector((state) => state.gameState);

  return (
    <div>
      <h3>
        {gameState === GameState.Won ? 'Ganaste' : 'Perdiste'}! La respuesta era{' '}
        <span style={{ fontWeight: 'bold' }}>{targetWord}</span>
      </h3>
      <Definitions word={targetWord} />
      <Share maxGuesses={6} pillTheme="light" />
    </div>
  );
};

export const StatsModal = (props: ComponentProps<typeof Modal>) => {
  const dispatch = useDispatch();
  const userStats = useSelector((state) => state.userStats);
  const statsStatus = useSelector((state) => state.statsStatus);
  const gameState = useSelector((state) => state.gameState);
  const [winningRate, setWinningRate] = useState<number>(0);

  useEffect(() => {
    if (props.isOpen && userStats === null) {
      dispatch(loadUserStats());
    }
  }, [props.isOpen, userStats, dispatch]);

  useEffect(() => {
    if (statsStatus === 'ready' && userStats) {
      setWinningRate(
        Math.round((userStats.gamesWon * 100) / userStats.gamesPlayed),
      );
    }
  }, [userStats, statsStatus]);

  return (
    <Modal {...props} style={{ padding: '0px' }}>
      <div className={styles.content}>
        <div className={styles['logo-container']}>
          <img src={logo} />
          <h2>Palabr</h2>
        </div>
        {gameState !== GameState.Playing && <DailyResults />}
        <h3 style={{ fontSize: '14px' }}>ESTADÍSTICAS</h3>
        {statsStatus === 'error' ? (
          <div>Ups... Algo salió mal</div>
        ) : (
          <div>
            <div className={styles['numbers-container']}>
              <NumberLabel
                value={userStats?.gamesPlayed}
                label="Juegos jugados"
              />
              <NumberLabel value={winningRate} label="% Victorias" />
              <NumberLabel
                value={userStats?.currentStreak}
                label="Racha actual"
              />
              <NumberLabel value={userStats?.maxStreak} label="Racha máx." />
            </div>
            <h3 style={{ fontSize: '14px' }}>DISTRIBUCIÓN DE JUGADAS</h3>
            <div>
              {['1', '2', '3', '4', '5', '6'].map((key) => {
                const value = userStats?.guessDistribution[key] || 0;

                return (
                  <DistribBar
                    key={`distrib-bar-${key}`}
                    label={key}
                    value={value}
                    total={userStats?.gamesPlayed || 0}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
