import { useEffect, useState } from 'react';

import { Status } from '../types';

const LOCAL_DICT = 'local-dict';

interface Dictionary {
  words: string[];
  version: number;
}

const fetchDictionary = async (): Promise<Dictionary> => {
  const response = await fetch('/.netlify/functions/get-dictionary');

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  return response.json() as unknown as Dictionary;
};

const loadDictionary = async (): Promise<string[]> => {
  const localDictionary = localStorage.getItem(LOCAL_DICT);

  if (localDictionary !== null) {
    const { words, version }: Dictionary = JSON.parse(localDictionary);

    const currentVersion = parseInt(
      process.env.REACT_APP_DICT_VERSION || '0',
      10,
    );

    if (currentVersion === version) {
      return words;
    }
  }

  const { words, version }: Dictionary = await fetchDictionary();

  localStorage.setItem(LOCAL_DICT, JSON.stringify({ words, version }));

  return words;
};

const useDictionary = () => {
  const [dictionary, setDictionary] = useState(new Set<string>());
  const [status, setStatus] = useState<Status>('loading');

  useEffect(() => {
    setStatus('loading');

    loadDictionary()
      .then((dict) => {
        setDictionary(new Set<string>(dict));
        setStatus('ready');
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
      });
  }, []);

  return {
    dictionary,
    status,
  };
};

export default useDictionary;
