import { useEffect, useState } from 'react';

export const useAnimatedNumber = ({
  from,
  to,
  duration = 1000,
}: {
  from: number;
  to: number;
  duration?: number;
}): number => {
  const [currentValue, setCurrentValue] = useState(0);

  if (from > to) {
    console.error('Bad input');
    return to;
  }

  useEffect(() => {
    const interval = setInterval(
      () => {
        setCurrentValue((v) => {
          if (v < to) {
            return v + 1;
          }

          if (v > to) {
            return to;
          }

          return v;
        });
      },
      duration / (to - from),
    );

    return () => clearInterval(interval);
  }, [from, to, duration]);

  return currentValue;
};
