import { useEffect, useState } from 'react';

import { Status } from '../types';

const getDefinitions = async (targetWord: string): Promise<string[]> => {
  const response = await fetch('/.netlify/functions/get-definitions', {
    method: 'POST',
    body: JSON.stringify({
      word: targetWord,
    }),
  });

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  const { definitions }: { definitions: string[] } = await response.json();

  return definitions;
};

const useWordDefinition = (targetWord: string) => {
  const [definitions, setDefinitions] = useState<string[]>([]);
  const [status, setStatus] = useState<Status>('loading');

  useEffect(() => {
    setStatus('loading');

    if (targetWord) {
      getDefinitions(targetWord)
        .then((loadedDefinitions) => {
          setDefinitions(loadedDefinitions);
          setStatus('ready');
        })
        .catch((err) => {
          console.error(err);
          setStatus('error');
        });
    }
  }, [targetWord]);

  return {
    definitions,
    status,
  };
};

export default useWordDefinition;
