import { useEffect, useState } from 'react';

import { Status } from '../types';
import useDictionary from './dictionary-hook';
import { useSelector } from './index';
import useTargetWord from './target-word-hook';

const deriveStatus = (statuses: Status[]): Status => {
  let anyError = false;
  let anyLoading = false;

  for (const status of statuses) {
    if (status === 'error') {
      anyError = true;
      break;
    }

    if (status === 'loading') {
      anyLoading = true;
    }
  }

  if (anyError) {
    return 'error';
  }

  if (anyLoading) {
    return 'loading';
  }

  return 'ready';
};

const useAppStatus = () => {
  const [appStatus, setAppStatus] = useState<Status>('loading');
  const savingStatus = useSelector((state) => state.savingStatus);
  const loadingStatus = useSelector((state) => state.loadingStatus);

  const { status: dictStatus } = useDictionary();
  const { status: targetStatus } = useTargetWord();

  useEffect(() => {
    setAppStatus(
      deriveStatus([dictStatus, targetStatus, savingStatus, loadingStatus]),
    );
  }, [dictStatus, targetStatus, savingStatus, loadingStatus]);

  return appStatus;
};

export default useAppStatus;
