import netlifyIdentity from 'netlify-identity-widget';

export const authorizedFetch = async (
  url: string,
  options: Parameters<typeof fetch>[1] = {},
) => {
  const user = netlifyIdentity.currentUser();

  if (!user) {
    throw new Error('Missing user');
  }

  const refreshedToken = await netlifyIdentity.refresh();

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${refreshedToken}`,
    },
    ...options,
  });
};
