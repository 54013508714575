import { GameState, SerializableAction, UserStats } from '../types';
import { authorizedFetch } from '../utils/authorized-fetch';

type GameStatusResponse = {
  guesses: Array<string>;
  gameState: GameState;
};

type SaveGameParams = {
  payload: SerializableAction;
  type: string;
  gameDate?: string;
};

export const loadGameState = async (
  gameDate: string,
): Promise<GameStatusResponse | null> => {
  const response = await authorizedFetch(
    `/.netlify/functions/get-game-status?game-date=${gameDate}`,
  );

  if (!response.ok) {
    if (response.status === 404) {
      const body = await response.json();

      if (body.message === 'No game found') {
        return null;
      }
    }

    return Promise.reject(new Error('Response not ok'));
  }

  return response.json() as Promise<GameStatusResponse>;
};

export const saveGame = async ({ payload, type, gameDate }: SaveGameParams) => {
  if (!gameDate) {
    return Promise.reject(new Error('Missing game date'));
  }

  const response = await authorizedFetch(
    `/.netlify/functions/save-game-status?game-date=${gameDate}`,
    {
      method: 'POST',
      body: JSON.stringify({ payload, type }),
    },
  );

  if (!response.ok) {
    const body = await response.json();

    return Promise.reject(new Error(body.message));
  }
};

export const fetchUserStats = async (): Promise<UserStats> => {
  const response = await authorizedFetch('/.netlify/functions/get-user-stats');

  if (!response.ok) {
    return Promise.reject(new Error('Response not ok'));
  }

  return response.json() as Promise<UserStats>;
};
