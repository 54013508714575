import './index.css';

import netlifyIdentity from 'netlify-identity-widget';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';

netlifyIdentity.init({
  locale: 'es',
});

netlifyIdentity.on('login', () => {
  // closes as soon as we are redirected from oauth
  netlifyIdentity.close();
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
