export enum Clue {
  Absent,
  Elsewhere,
  Correct,
}

type ClueClass = 'letter-absent' | 'letter-elsewhere' | 'letter-correct';

export function getClueClass(clue: Clue): ClueClass {
  if (clue === Clue.Absent) {
    return 'letter-absent';
  } else if (clue === Clue.Elsewhere) {
    return 'letter-elsewhere';
  } else {
    return 'letter-correct';
  }
}
