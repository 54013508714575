import { useEffect, useState } from 'react';

import { Status } from '../types';
import { authorizedFetch } from '../utils/authorized-fetch';
import { useSelector } from './index';

interface CachedWord {
  word: string;
  date: string;
}

export const TARGET_WORD = 'target-word-v0.0.2';

const chooseTarget = async (gameDate: string): Promise<string> => {
  const cachedWord = localStorage.getItem(TARGET_WORD);

  if (cachedWord !== null) {
    const { word, date }: CachedWord = JSON.parse(cachedWord);

    if (date === gameDate) {
      return word.toLowerCase();
    }
  }

  const response = await authorizedFetch(
    `/.netlify/functions/get-word?game-date=${gameDate}`,
  );

  if (!response.ok) {
    throw new Error('Response not ok');
  }

  const { word } = await response.json();

  localStorage.setItem(TARGET_WORD, JSON.stringify({ word, date: gameDate }));

  return word;
};

const useTargetWord = () => {
  const gameDate = useSelector((state) => state.gameDate);
  const [targetWord, setTargetWord] = useState('');
  const [status, setStatus] = useState<Status>('loading');

  useEffect(() => {
    setStatus('loading');
    if (gameDate) {
      chooseTarget(gameDate)
        .then((word) => {
          setTargetWord(word);
          setStatus('ready');
        })
        .catch((err) => {
          console.error(err);
          setStatus('error');
        });
    }
  }, [gameDate]);

  return {
    targetWord,
    status,
  };
};

export default useTargetWord;
