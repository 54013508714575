import birthdayHat from '../../images/birthday-hat.png';
import mofiImage from '../../images/mofi.webp';
import styles from './Mofi.module.css';

export const Mofi = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <img className={styles.hat} src={birthdayHat} alt="Gorro de cumpleaños" />
      <img
        className={styles.mofi}
        src={mofiImage}
        alt="El mejor perro del mundo"
      />
    </div>
  );
};
