import { useCallback, useEffect, useState } from 'react';

import { useSelector } from '../../hooks';
import { type CluedLetter } from '../../hooks/clues-hook';
import { Clue, getClueClass } from '../../utils/clue';
import styles from './row.module.css';

interface RowProps {
  isLockedIn: boolean;
  wordLength: number;
  letters: CluedLetter[] | string;
  toLeft?: true;
}

type GameLetterProps = {
  letter: string;
  animationDelay: number;
  clue?: Clue;
};

const GameLetter = ({ letter, clue, animationDelay }: GameLetterProps) => {
  const [classNames, setClassNames] = useState<string[]>([]);
  const savingStatus = useSelector((state) => state.savingStatus);

  const addClass = useCallback((className: string) => {
    setClassNames((prev) => {
      const classes = new Set(prev);

      classes.add(className);

      return Array.from(classes);
    });
  }, []);

  const removeClass = useCallback((className: string) => {
    setClassNames((prev) => {
      const classes = new Set(prev);

      classes.delete(className);

      return Array.from(classes);
    });
  }, []);

  useEffect(() => {
    if (clue === undefined) {
      return;
    }

    removeClass(styles['bump']);

    const flipInTimeout = setTimeout(() => {
      addClass(styles['flip-in']);
    }, animationDelay);

    const flipOutTimeout = setTimeout(() => {
      addClass(styles['flip-out']);
      addClass(styles[getClueClass(clue)]);
    }, animationDelay + 250);

    return () => {
      clearTimeout(flipInTimeout);
      clearTimeout(flipOutTimeout);
    };
  }, [clue, animationDelay, addClass, removeClass]);

  useEffect(() => {
    if (clue !== undefined || letter === '') {
      removeClass(styles['flip']);
      return;
    }

    if (savingStatus === 'loading') {
      addClass(styles['flip']);
    } else {
      removeClass(styles['flip']);
    }
  }, [clue, addClass, removeClass, savingStatus, letter, animationDelay]);

  useEffect(() => {
    if (clue === undefined) {
      if (letter) {
        addClass(styles['bump']);
      } else {
        removeClass(styles['bump']);
      }
    }
  }, [letter, clue, addClass, removeClass]);

  return (
    <div className={`${styles['row-letter']} ${classNames.join(' ')}`}>
      {letter}
    </div>
  );
};

export function Row({ isLockedIn, letters, wordLength, toLeft }: RowProps) {
  const letterDivs: CluedLetter[] =
    typeof letters === 'string'
      ? letters
          .split('')
          .map((letter) => ({
            clue: Clue.Absent,
            letter: letter,
          }))
          .concat(Array(wordLength).fill({ letter: '', clue: Clue.Absent }))
          .slice(0, wordLength)
      : letters;

  return (
    <div
      className={styles['row']}
      style={{ justifyContent: toLeft ? 'left' : 'center' }}
    >
      {letterDivs.map(({ letter, clue }, i) => (
        <GameLetter
          key={i}
          letter={letter}
          clue={isLockedIn ? clue : undefined}
          animationDelay={i * 250}
        />
      ))}
    </div>
  );
}
