import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import { useSelector } from '../hooks';
import { GameState } from '../types';
import styles from './BirthdayGreeting.module.css';
import { Mofi } from './Mofi/Mofi';
import { SpeechBubble } from './SpeechBubble';

export const BirthdayGreeting = ({ messages }: { messages: string[] }) => {
  const { gameState } = useSelector((state) => state);
  const [hasBeenClosed, setHasBeenClosed] = useState(false);
  const [showGreeting, setShowGreeting] = useState(false);

  useEffect(() => {
    setShowGreeting(gameState !== GameState.Playing && !hasBeenClosed);
  }, [gameState, hasBeenClosed]);

  if (showGreeting) {
    return (
      <div onClick={() => setHasBeenClosed(true)}>
        <Confetti className={styles.confetti} />
        <div className={styles['mofi-container']}>
          <SpeechBubble>
            {messages.map((message) => (
              <p className={styles['greeting-message']}> {message}</p>
            ))}
            <p className={styles['close-message']}>(toca para cerrar)</p>
          </SpeechBubble>
          <Mofi className={styles.mofi} />
        </div>
      </div>
    );
  }

  return null;
};
