import { ReactNode } from 'react';

import styles from './SpeechBubble.module.css';

export const SpeechBubble = ({ children }: { children: ReactNode }) => {
  return (
    <div className={`${styles.bubble} ${styles['bubble-bottom-left']}`}>
      {children}
    </div>
  );
};
