import { useEffect, useState } from 'react';

import { Clue } from '../utils/clue';
import { useSelector } from './index';
import useTargetWord from './target-word-hook';
export type CluedLetter = {
  clue: Clue;
  letter: string;
};

const getClue = (guess: string, target: string): CluedLetter[] => {
  const misses: string[] = [];

  target.split('').forEach((letter, i) => {
    if (guess[i] !== letter) {
      misses.push(letter);
    }
  });

  return guess.split('').map((letter, i) => {
    const missedLetterIndex = misses.indexOf(letter);
    let clue = Clue.Absent;

    if (target[i] === letter) {
      clue = Clue.Correct;
    } else if (missedLetterIndex > -1) {
      // "use it up" so we don't clue at it twice
      misses[missedLetterIndex] = '';
      clue = Clue.Elsewhere;
    }

    return { clue, letter };
  });
};

export const useClues = () => {
  const { targetWord } = useTargetWord();
  const [keyboardClues, setKeyboardClues] = useState<Record<string, Clue>>({});
  const [cluedGuesses, setCluedGuesses] = useState<CluedLetter[][]>([]);
  const guesses = useSelector((state) => state.guesses);

  useEffect(() => {
    if (targetWord) {
      guesses.forEach((guess, i) => {
        if (cluedGuesses[i]) {
          return;
        }

        const cluedGuess = getClue(guess, targetWord);

        for (const { clue, letter } of cluedGuess) {
          const currentClue = keyboardClues[letter];

          if (currentClue === undefined || clue > currentClue) {
            setKeyboardClues((prev) => ({ ...prev, [letter]: clue }));
          }
        }

        setCluedGuesses((prev) => [...prev, cluedGuess]);
      });
    }
  }, [targetWord, guesses, cluedGuesses, keyboardClues]);

  return { cluedGuesses, keyboardClues };
};
