import netlifyIdentity, { type User } from 'netlify-identity-widget';

import { useDispatch, useSelector } from '../../hooks';
import { ReactComponent as AccountCircle } from '../../images/account_circle_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as Chart } from '../../images/bar_chart_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as Help } from '../../images/help_FILL0_wght400_GRAD0_opsz24.svg';
import { displayAbout, displayStats } from '../../store';
import styles from './header.module.css';
import { HowToPlayModal } from './how-to-play-modal/how-to-play-modal';
import { StatsModal } from './stats-modal/stats-modal';

export function Header({ user }: { user: null | User }) {
  const dispatch = useDispatch();
  const showAbout = useSelector((state) => state.showAbout);
  const showStats = useSelector((state) => state.showStats);

  return (
    <>
      <StatsModal
        isOpen={showStats}
        onClose={() => dispatch(displayStats(false))}
      />
      <HowToPlayModal
        isOpen={showAbout}
        onClose={() => {
          dispatch(displayAbout(false));
        }}
      />
      <div className={styles['container']}>
        <div> </div>
        <div className={styles.logo}>
          <a href="#">Palabr</a>
        </div>
        <div className={styles.icons}>
          <button
            className={styles.account}
            onClick={() => netlifyIdentity.open()}
          >
            <AccountCircle />
          </button>
          {user && (
            <button className={styles.chart}>
              <Chart
                onClick={() => {
                  dispatch(displayStats(true));
                }}
              />
            </button>
          )}
          <button
            className={styles.help}
            onClick={() => dispatch(displayAbout(true))}
          >
            <Help />
          </button>
        </div>
      </div>
    </>
  );
}
