import { useCallback, useEffect, useState } from 'react';

import { GiveUpButton } from './components/give-up-button/give-up-button';
import { Hint } from './components/hint/hint';
import { Keyboard } from './components/keyboard/keyboard';
import { Row } from './components/row/row';
import Share from './components/share/share';
import Countdown from './Countdown';
import Definitions from './Definitions/Definitions';
import { useDispatch, useSelector } from './hooks';
import useAppStatus from './hooks/app-status';
import { CluedLetter, useClues } from './hooks/clues-hook';
import useDictionary from './hooks/dictionary-hook';
import useTargetWord from './hooks/target-word-hook';
import {
  addAndSaveGuess,
  addGuessLetter,
  addLoosingGuessAndSave,
  addWinningGuessAndSave,
  displayStats,
  loadGame,
  removeGuessLetter,
  updateGameDate,
  updateHint,
} from './store';
import { GameState } from './types';

type GameProps = {
  maxGuesses: number;
  hidden: boolean;
  greetingMode?: boolean;
};

type BoardProps = {
  maxGuesses: number;
  wordLength: number;
  cluedGuesses: CluedLetter[][];
  guesses: string[];
  currentGuess: string;
};

type ResultsProps = {
  gameState: GameState;
  targetWord: string;
  maxGuesses: number;
};

const Board = ({
  maxGuesses,
  wordLength,
  guesses,
  cluedGuesses,
  currentGuess,
}: BoardProps) => {
  return (
    <>
      {Array(maxGuesses)
        .fill(undefined)
        .map((_, i) => (
          <Row
            key={i}
            wordLength={wordLength}
            isLockedIn={i < guesses.length}
            letters={
              cluedGuesses[i] || (i === guesses.length ? currentGuess : '')
            }
          />
        ))}
    </>
  );
};

const Results = ({ gameState, targetWord, maxGuesses }: ResultsProps) => {
  return (
    <>
      <p>
        {gameState === GameState.Won
          ? 'Ganaste!'
          : `Perdiste! La respuesta era ${targetWord}.`}
      </p>
      <Definitions word={targetWord} />
      <Share maxGuesses={maxGuesses} />
      <Countdown />
    </>
  );
};

function Game(props: GameProps) {
  const dispatch = useDispatch();
  const currentGuess = useSelector((state) => state.currentGuess);
  const gameState = useSelector((state) => state.gameState);
  const guesses = useSelector((state) => state.guesses);
  const wordLength = useSelector((state) => state.wordLength);
  const gameDate = useSelector((state) => state.gameDate);
  const savingStatus = useSelector((state) => state.savingStatus);

  const status = useAppStatus();
  const { dictionary } = useDictionary();
  const { targetWord } = useTargetWord();
  const { cluedGuesses, keyboardClues } = useClues();
  const [showResults, setShowResults] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(true);
  const [showHint, setShowHint] = useState(true);

  const playWord = useCallback(() => {
    if (currentGuess.length !== wordLength) {
      dispatch(updateHint('Muy corta'));
      return;
    }

    if (!dictionary.has(currentGuess)) {
      dispatch(updateHint('Palabra inválida'));
      return;
    }

    if (currentGuess === targetWord) {
      dispatch(addWinningGuessAndSave({ value: currentGuess }));
    } else if (guesses.length + 1 === props.maxGuesses) {
      dispatch(addLoosingGuessAndSave({ value: currentGuess }));
    } else {
      dispatch(addAndSaveGuess({ value: currentGuess }));
    }
  }, [
    currentGuess,
    dictionary,
    guesses.length,
    dispatch,
    props.maxGuesses,
    targetWord,
    wordLength,
  ]);

  const onKey = useCallback(
    (key: string) => {
      if (gameState !== GameState.Playing) {
        return;
      }

      if (guesses.length === props.maxGuesses) return;
      if (/^[a-zñ]$/.test(key)) {
        dispatch(addGuessLetter(key));
      } else if (key === 'Backspace') {
        dispatch(removeGuessLetter());
      } else if (key === 'Enter') {
        playWord();
      }
    },
    [gameState, guesses.length, dispatch, playWord, props.maxGuesses],
  );

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey && savingStatus === 'ready') {
        onKey(e.key);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKey, savingStatus]);

  useEffect(() => {
    dispatch(updateGameDate());
  }, [dispatch]);

  useEffect(() => {
    if (gameDate && targetWord) {
      dispatch(
        loadGame({ gameDate, maxGuesses: props.maxGuesses, targetWord }),
      );
    }
  }, [dispatch, gameDate, targetWord, props.maxGuesses]);

  useEffect(() => {
    if (gameState === GameState.Playing) {
      return;
    }

    const finishedGame = setTimeout(() => {
      setShowResults(true);
      setShowKeyboard(false);
      setShowHint(false);
      dispatch(displayStats(true));
    }, 250 * 6);

    return () => clearTimeout(finishedGame);
  }, [gameState, dispatch]);

  return (
    <div className="Game" style={{ display: props.hidden ? 'none' : 'block' }}>
      {status === 'error' ? (
        <p>Algo salió mal... Refresca la página e intenta de nuevo</p>
      ) : (
        <>
          <div className="Game-options">{<GiveUpButton />}</div>
          <Board
            maxGuesses={props.maxGuesses}
            wordLength={wordLength}
            cluedGuesses={cluedGuesses}
            currentGuess={currentGuess}
            guesses={guesses}
          />
          {showHint && <Hint />}
          {showResults && (
            <Results
              gameState={gameState}
              targetWord={targetWord}
              maxGuesses={props.maxGuesses}
            />
          )}
          {showKeyboard && (
            <Keyboard keyboardClues={keyboardClues} onKey={onKey} />
          )}
        </>
      )}
    </div>
  );
}

export default Game;
