import './App.css';

import type { User } from 'netlify-identity-widget';
import netlifyIdentity from 'netlify-identity-widget';
import { useCallback, useEffect, useState } from 'react';

import { BirthdayGreeting } from './BirthdayGreeting/BirthdayGreeting';
import { Header } from './components/header/header';
import { Welcome } from './components/welcome/welcome';
import Game from './Game';
import greetings from './greeting-overrides/greetings.json';
import { useLoginModal } from './hooks/login-modal-hook';
import { TARGET_WORD } from './hooks/target-word-hook';
import { getTodayString } from './utils/date-utils';

function App() {
  const about = false;
  const [user, setUser] = useState<null | User>(netlifyIdentity.currentUser());
  const greetingData = (greetings as GreetingsData)[getTodayString()];
  const maxGuesses = 6;

  useLoginModal();

  const loginHandler = useCallback((signedInUser: User) => {
    localStorage.removeItem(TARGET_WORD);
    setUser(signedInUser);
  }, []);

  const logoutHandler = useCallback(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    netlifyIdentity.on('login', loginHandler);
    netlifyIdentity.on('logout', logoutHandler);

    return () => {
      netlifyIdentity.off('login', loginHandler);
      netlifyIdentity.off('logout', logoutHandler);
    };
  }, [loginHandler, logoutHandler]);

  return (
    <>
      {greetingData && <BirthdayGreeting messages={greetingData.greeting} />}
      <Header user={user} />
      {
        <div className="App-container">
          {user ? (
            <Game
              maxGuesses={maxGuesses}
              hidden={about}
              greetingMode={!!greetingData}
            />
          ) : (
            <Welcome />
          )}
        </div>
      }
    </>
  );
}

export default App;
