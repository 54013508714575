import { type HTMLAttributes, useEffect, useRef, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../images/close_FILL0_wght400_GRAD0_opsz24.svg';
import styles from './modal.module.css';

type ModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export const Modal = ({ isOpen, onClose, children, style }: ModalProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(Boolean(isOpen));

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <div
      className={styles['overlay']}
      ref={overlayRef}
      onClick={(event) => {
        if (event.target === overlayRef.current) {
          onClose();
        }
      }}
      style={{
        ...(showModal ? {} : { display: 'none' }),
      }}
    >
      <div className={styles['content']} style={style}>
        {/* TITLE */}
        <CloseIcon
          className={styles['close-button']}
          onClick={() => onClose()}
        />
        {children}
        {/* BODY */}
      </div>
    </div>
  );
};
