export type Status = 'loading' | 'ready' | 'error';
export type Theme = 'light' | 'dark';

export enum GameState {
  Playing,
  Won,
  Lost,
}

export type SerializableAction = { value: string } | undefined;

export type DbGameStatus = {
  user_email: string;
  game_date: string;
  guesses: Array<string>;
  game_state: GameState;
  created_at: string;
  updated_at: string;
};

export type DbUserStats = {
  user_email: string;
  games_played: number;
  games_won: number;
  current_streak: number;
  max_streak: number;
  guess_dist: Record<string, number>;
  last_won: null | string;
  created_at: string;
  updated_at: string;
};

export type UserStats = {
  gamesPlayed: number;
  gamesWon: number;
  currentStreak: number;
  maxStreak: number;
  guessDistribution: Record<string, number>;
  lastWon: null | string;
};
