import netlifyIdentity from 'netlify-identity-widget';

import { useDispatch } from '../../hooks';
import { ReactComponent as Logo } from '../../images/Emoji_u1f40d.svg';
import { displayAbout } from '../../store';
import { PillButton } from '../pill-button/pill-button';
import styles from './welcome.module.css';

export function Welcome() {
  const dispatch = useDispatch();

  return (
    <div className={styles['modal-box']}>
      <div className={styles['modal-content']}>
        <Logo />
        <h2>Palabr</h2>
        <p>Tienes 6 intentos para adivinar una palabra de 5 letras.</p>
        <div className={styles['button-container']}>
          <div>
            <PillButton onClick={() => dispatch(displayAbout(true))}>
              Cómo jugar
            </PillButton>
          </div>
          <div>
            <PillButton
              className={styles['modal-button']}
              onClick={() => netlifyIdentity.open()}
            >
              Ingresar
            </PillButton>
          </div>
        </div>
      </div>
    </div>
  );
}
