import { useCallback, useEffect, useState } from 'react';
import { shareOnMobile } from 'react-mobile-share';

import { useSelector } from '../../hooks';
import { type CluedLetter, useClues } from '../../hooks/clues-hook';
import { useTheme } from '../../hooks/use-theme';
import { GameState } from '../../types';
import { type Theme } from '../../types';
import { Clue } from '../../utils/clue';
import { PillButton } from '../pill-button/pill-button';
import styles from './share.module.css';

interface ShareProps {
  maxGuesses: number;
  pillTheme?: Theme;
}

const getResultsToShare = (
  cluedGuesses: CluedLetter[][],
  maxGuesses: number,
  gameState: GameState,
  theme: Theme,
  gameDate?: string,
) => {
  const absentBlock = theme === 'dark' ? '⬛' : '⬜';
  const usedGuesses = gameState === GameState.Won ? cluedGuesses.length : 'X';
  const rows = [`Palabr ${gameDate} ${usedGuesses}/${maxGuesses}`];

  cluedGuesses.forEach((cluedGuess) => {
    let currentRow = '';

    for (const { clue } of cluedGuess) {
      switch (clue) {
        case Clue.Correct:
          currentRow += '🟩';
          break;
        case Clue.Elsewhere:
          currentRow += '🟨';
          break;
        default:
          currentRow += absentBlock;
      }
    }

    rows.push(currentRow);
  });

  return rows.join('\n');
};

const Share = ({ maxGuesses, pillTheme }: ShareProps) => {
  const { cluedGuesses } = useClues();

  const [textCopied, setTextCopied] = useState(false);
  const [buttonBumping, setButtonBumping] = useState(false);
  const gameState = useSelector((state) => state.gameState);
  const gameDate = useSelector((state) => state.gameDate);
  const theme = useTheme();
  const textToShare = getResultsToShare(
    cluedGuesses,
    maxGuesses,
    gameState,
    theme,
    gameDate,
  );

  const onShare = useCallback(() => {
    shareOnMobile(
      {
        title: textToShare,
      },
      (message: string) => {
        try {
          switch (message) {
            case "Can't share on this, make sure you are running on Android or iOS devices":
              navigator.clipboard.writeText(textToShare);
              setTextCopied(true);
              setButtonBumping(true);
              break;
            case 'Share canceled':
              return;
            default:
              throw new Error(message);
          }
        } catch (error) {
          console.error(error);
        }
      },
    );
  }, [textToShare]);

  useEffect(() => {
    if (buttonBumping) {
      const timeout = setTimeout(() => {
        setButtonBumping(false);
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [buttonBumping]);

  const buttonStyle = [styles['share-button']];
  const buttonText = textCopied ? 'Resultados copiados!' : 'Compartir';

  if (textCopied) {
    buttonStyle.push(styles['copied']);
  }

  if (buttonBumping) {
    buttonStyle.push(styles.bump);
  }

  return (
    <div className={styles['share-container']}>
      <PillButton
        className={buttonStyle.join(' ')}
        onClick={onShare}
        theme={pillTheme}
      >
        <span>{buttonText}</span>
      </PillButton>
    </div>
  );
};

export default Share;
