import { type ComponentProps } from 'react';

import { Clue } from '../../../utils/clue';
import { Modal } from '../../modal/modal';
import { Row } from '../../row/row';
import styles from './how-to-play-modal.module.css';

export const HowToPlayModal = (props: ComponentProps<typeof Modal>) => {
  return (
    <Modal {...props}>
      <ul className={styles.contributors}>
        <li>
          <i>Palabr</i> es un remake del juego de palabras{' '}
          <a href="https://www.nytimes.com/games/wordle" target="_blank">
            <i>Wordle</i>
          </a>
          .
        </li>
        <li>
          Este proyecto es un fork de{' '}
          <a href="https://github.com/lynn/hello-wordl" target="_blank">
            <i>hello wordl</i>
          </a>
          .
        </li>
      </ul>
      <h2>Cómo jugar</h2>
      <h3>Adivina la palabra en 6 intentos.</h3>
      <ul className={styles['instructions']}>
        <li>Cada intento debe ser una palabra válida de 5 letras.</li>
        <li>
          El color de las casillas cambiará para mostrarte qué tan cerca estuvo
          tu suposición de la palabra.
        </li>
      </ul>
      <Row
        isLockedIn
        wordLength={5}
        toLeft
        letters={[
          { clue: Clue.Absent, letter: 'p' },
          { clue: Clue.Elsewhere, letter: 'a' },
          { clue: Clue.Elsewhere, letter: 'r' },
          { clue: Clue.Correct, letter: 'd' },
          { clue: Clue.Correct, letter: 'o' },
        ]}
      />
      <p>
        <b>P</b> no es parte de la palabra
        <br />
        <b>A</b> y <b>R</b> están <em>en otra posición</em> en la palabra final
        <br />
        <b>D</b> y <b>O</b> están correctas! La cuarta letra es <b>D</b> y la
        quinta letra es <b>O</b>
      </p>
      <p>
        Movamos la <b>R</b> y la <b>A</b> en nuestro siguiente intento:
      </p>
      <Row
        isLockedIn
        wordLength={5}
        toLeft
        letters={[
          { clue: Clue.Absent, letter: 'p' },
          { clue: Clue.Correct, letter: 'r' },
          { clue: Clue.Correct, letter: 'a' },
          { clue: Clue.Correct, letter: 'd' },
          { clue: Clue.Correct, letter: 'o' },
        ]}
      />
      Cerca!
      <Row
        isLockedIn
        wordLength={5}
        toLeft
        letters={[
          { clue: Clue.Correct, letter: 'g' },
          { clue: Clue.Correct, letter: 'r' },
          { clue: Clue.Correct, letter: 'a' },
          { clue: Clue.Correct, letter: 'd' },
          { clue: Clue.Correct, letter: 'o' },
        ]}
      />
      <p>Logrado!</p>
      Para sugerir correcciones o nuevas palabras{' '}
      <a href="https://twitter.com/conihesp" target="_blank" rel="noreferrer">
        @conihesp
      </a>
      <br />
      Publicado por{' '}
      <a href="https://mapolonio.dev" target="_blank" rel="noreferrer">
        mapolonio
      </a>
      {' & '}
      <a href="https://github.com/mchinojo" target="_blank" rel="noreferrer">
        mchinojo
      </a>
    </Modal>
  );
};
