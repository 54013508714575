import {
  configureStore,
  createAction,
  createReducer,
  type PayloadAction,
  type ThunkAction,
  ThunkDispatch,
  type UnknownAction,
} from '@reduxjs/toolkit';

import { GameState, SerializableAction, Status, UserStats } from '../types';
import { getTodayString } from '../utils/date-utils';
import { fetchUserStats, loadGameState, saveGame } from './fetch-utils';

export interface ReduxGame {
  gameState: GameState;
  guesses: string[];
  currentGuess: string;
  wordLength: number;
  hint: string;
  showAbout: boolean;
  showStats: boolean;
  gameDate?: string;
  savingStatus: Status;
  loadingStatus: Status;
  statsStatus: Status;
  userStats: UserStats | null;
}

type HandleSaveParams = {
  payload: SerializableAction;
  type: string;
  getState: () => ReduxGame;
  dispatch: ThunkDispatch<ReduxGame, unknown, UnknownAction>;
  onSuccess: () => void;
};

const INITIAL_STATE: ReduxGame = {
  gameState: GameState.Playing,
  guesses: [],
  currentGuess: '',
  wordLength: 5,
  hint: 'Prueba tu primera palabra!',
  showAbout: false,
  showStats: false,
  gameDate: undefined,
  savingStatus: 'ready',
  loadingStatus: 'loading',
  statsStatus: 'loading',
  userStats: null,
};

export const winGame = createAction('win-game');
export const looseGame = createAction('loose-game');
export const addGuess = createAction<string>('add-guess');
export const addGuessLetter = createAction<string>('add-guess-letter');
export const removeGuessLetter = createAction('remove-guess-letter');
export const updateHint = createAction<string>('update-hint');
export const displayAbout = createAction<boolean>('display-about');
export const displayStats = createAction<boolean>('display-stats');
export const updateGameDate = createAction('update-game-date');
export const addGuessAndWin = createAction('add-guess-and-win');
export const addGuessAndLoose = createAction('add-guess-and-loose');
export const updateUserHasSeenStats = createAction<boolean>(
  'update-user-has-seen-stats',
);

const updateUserStats = createAction<UserStats>('update-user-stats');
const updateSavingStatus = createAction<Status>('update-saving-status');
const updateLoadingStatus = createAction<Status>('update-loading-status');
const updateStatsStatus = createAction<Status>('update-stats-status');
const resetUserStats = createAction('reset-user-stats');

const handleSaveGame = async ({
  payload,
  type,
  getState,
  dispatch,
  onSuccess,
}: HandleSaveParams) => {
  dispatch(updateSavingStatus('loading'));

  try {
    const { gameDate } = getState();

    await saveGame({ payload, type, gameDate });

    dispatch(updateSavingStatus('ready'));
    onSuccess();
  } catch (error) {
    console.error(error);

    dispatch(updateSavingStatus('error'));
  }
};

export const addAndSaveGuess =
  (
    payload: NonNullable<SerializableAction>,
  ): ThunkAction<void, ReduxGame, unknown, UnknownAction> =>
  (dispatch, getState) =>
    handleSaveGame({
      payload,
      type: addGuess.toString(),
      getState,
      dispatch,
      onSuccess: () => {
        dispatch(addGuess(payload.value));
      },
    });

export const looseAndSave =
  (
    payload?: SerializableAction,
  ): ThunkAction<void, ReduxGame, unknown, UnknownAction> =>
  (dispatch, getState) =>
    handleSaveGame({
      payload,
      type: looseGame.toString(),
      getState,
      dispatch,
      onSuccess: () => {
        dispatch(looseGame());
        dispatch(resetUserStats());
      },
    });

export const addWinningGuessAndSave =
  (
    payload: NonNullable<SerializableAction>,
  ): ThunkAction<void, ReduxGame, unknown, UnknownAction> =>
  (dispatch, getState) =>
    handleSaveGame({
      payload,
      type: addGuessAndWin.toString(),
      getState,
      dispatch,
      onSuccess: () => {
        dispatch(addGuess(payload.value));
        dispatch(winGame());
        dispatch(resetUserStats());
      },
    });

export const addLoosingGuessAndSave =
  (
    payload: NonNullable<SerializableAction>,
  ): ThunkAction<void, ReduxGame, unknown, UnknownAction> =>
  (dispatch, getState) =>
    handleSaveGame({
      payload,
      type: addGuessAndLoose.toString(),
      getState,
      dispatch,
      onSuccess: () => {
        dispatch(addGuess(payload.value));
        dispatch(looseGame());
        dispatch(resetUserStats());
      },
    });

export const loadGame =
  (params: {
    gameDate: string;
    maxGuesses: number;
    targetWord: string;
  }): ThunkAction<void, ReduxGame, unknown, UnknownAction> =>
  async (dispatch) => {
    dispatch(updateLoadingStatus('loading'));

    try {
      const loadedState = await loadGameState(params.gameDate);

      if (loadedState !== null) {
        const { guesses, gameState } = loadedState;

        for (const guess of guesses) {
          dispatch(addGuess(guess));
        }

        switch (gameState) {
          case GameState.Won:
            dispatch(winGame());
            break;
          case GameState.Lost:
            dispatch(looseGame());
            break;
        }
      }

      dispatch(updateLoadingStatus('ready'));
    } catch (error) {
      console.error(error);
      dispatch(updateLoadingStatus('error'));
    }
  };

export const loadUserStats =
  (): ThunkAction<void, ReduxGame, unknown, UnknownAction> =>
  async (dispatch) => {
    try {
      dispatch(updateStatsStatus('loading'));

      const loadedStats = await fetchUserStats();

      dispatch(updateUserStats(loadedStats));
      dispatch(updateStatsStatus('ready'));
    } catch (error) {
      console.error(error);
      dispatch(updateStatsStatus('error'));
    }
  };

const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(winGame, (state) => {
      state.gameState = GameState.Won;
    })
    .addCase(looseGame, (state) => {
      state.gameState = GameState.Lost;
    })
    .addCase(addGuess, (state, action: PayloadAction<string>) => {
      const guess = action.payload;

      state.guesses.push(guess);

      state.currentGuess = '';
      state.hint = '';
    })
    .addCase(addGuessLetter, (state, action: PayloadAction<string>) => {
      state.currentGuess += action.payload;
      state.currentGuess = state.currentGuess.slice(0, state.wordLength);
      state.hint = '';
    })
    .addCase(removeGuessLetter, (state) => {
      state.currentGuess = state.currentGuess.slice(0, -1);
      state.hint = '';
    })
    .addCase(updateHint, (state, action: PayloadAction<string>) => {
      state.hint = action.payload;
    })
    .addCase(displayAbout, (state, action: PayloadAction<boolean>) => {
      state.showAbout = action.payload;
    })
    .addCase(displayStats, (state, action: PayloadAction<boolean>) => {
      state.showStats = action.payload;
    })
    .addCase(updateGameDate, (state) => {
      state.gameDate = getTodayString();
    })
    .addCase(updateSavingStatus, (state, action: PayloadAction<Status>) => {
      state.savingStatus = action.payload;
    })
    .addCase(updateLoadingStatus, (state, action: PayloadAction<Status>) => {
      state.loadingStatus = action.payload;
    })
    .addCase(updateStatsStatus, (state, action: PayloadAction<Status>) => {
      state.statsStatus = action.payload;
    })
    .addCase(updateUserStats, (state, action: PayloadAction<UserStats>) => {
      const {
        gamesPlayed,
        gamesWon,
        currentStreak,
        maxStreak,
        guessDistribution,
      } = action.payload;

      state.userStats ??
        (state.userStats = {
          gamesPlayed: 0,
          gamesWon: 0,
          currentStreak: 0,
          maxStreak: 0,
          guessDistribution: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0 },
          lastWon: null,
        });

      state.userStats.gamesPlayed += gamesPlayed;
      state.userStats.gamesWon += gamesWon;
      state.userStats.currentStreak += currentStreak;
      state.userStats.maxStreak += maxStreak;

      for (const key in guessDistribution) {
        state.userStats.guessDistribution[key] += guessDistribution[key];
      }
    })
    .addCase(resetUserStats, (state) => {
      state.userStats = null;
    });
});

const store = configureStore({
  reducer,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
