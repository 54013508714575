import useWordDefinition from '../hooks/word-definition-hook';
import styles from './Definitions.module.css';

const Definitions = ({ word }: { word: string }) => {
  const { definitions, status: definitionStatus } = useWordDefinition(word);

  if (definitionStatus === 'loading') {
    return <span>Cargando definición...</span>;
  }

  if (definitionStatus === 'error') {
    return <span>Ups... Algo salió mal</span>;
  }

  return (
    <div className={styles['container']}>
      <p className={styles.word}>{word}</p>
      <ul className={styles['list']}>
        {definitions.map((definition, index) => (
          <li key={`definition-${index}`}>
            <span>{index + 1}.</span> {definition}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Definitions;
